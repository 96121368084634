import { defineStore } from 'pinia';

export const useProgApplicationClosedStore = defineStore('applicationClosedStore', () => {
    var applicationClosedModal = ref<boolean>(false);
    var subProgrammeId = ref<string>("");
    var subProgrammeName = ref<string>("");
    var subProgrammeOpen = ref<boolean>(false);
    var isLoggedIn = ref<boolean>(false);

    const getApplicationClosedModal = computed(
        () => {
            return applicationClosedModal.value;
        }
    );

    function setIsLoggedIn(value: boolean) {
        isLoggedIn.value = value;
    }

    const getIsLoggedIn = computed(() => isLoggedIn.value);

    function isOpenApplicationClosedModal() {
        applicationClosedModal.value = true;
    }
    function isClosedApplicationClosedModal() {
        applicationClosedModal.value = false;
    }

    function setSubProgrammeId(name: string) {
        subProgrammeId.value = name;
    }

    function setSubProgrammeName(subName: string) {
        subProgrammeName.value = subName;
    }

    function setIsOpen(subProgState: boolean) {
        subProgrammeOpen.value = subProgState;
    }

    const getSubProgrammeId = computed(() => subProgrammeId.value);

    const getSubProgrammeName = computed(() => subProgrammeName.value);

    const getSubProgrammeState = computed(() => subProgrammeOpen.value);

    return {
        getApplicationClosedModal: getApplicationClosedModal,
        isOpenApplicationClosedModal: isOpenApplicationClosedModal,
        isClosedApplicationClosedModal: isClosedApplicationClosedModal,
        getSubProgrammeId: getSubProgrammeId,
        getSubProgrammeName: getSubProgrammeName,
        getSubProgrammeState: getSubProgrammeState,
        setSubProgrammeId,
        setSubProgrammeName,
        setIsOpen,
        setIsLoggedIn,
        getIsLoggedIn
    }
});