<template>
    <template v-if="props.textLinkLogoSpec.sourceUrl !== ''">
        <NuxtLink
            :id="props.textLinkLogoSpec.buttonElName" 
            class="gibs-text-link-logo" :class="props.textLinkLogoSpec.class" 
            :to='props.textLinkLogoSpec.sourceUrl'
            :target="openLinkInNewTab()" 
        >
        <template v-if="props.textLinkLogoSpec.type === 'signout'">
            <nuxt-icon class="signout-icon" name="buttons/signout" />
        </template>
        <template v-else-if="props.textLinkLogoSpec.type === 'download'">
            <nuxt-icon class="download-icon mr-2" name="buttons/download" />
        </template>
            <strong>{{ props.textLinkLogoSpec.buttonText }}</strong>
        </NuxtLink>
    </template>
</template>

<script setup lang="ts">

    const props = defineProps({
        textLinkLogoSpec: {
            type: Object,
            default: buttonObject
        }
    });

    function openLinkInNewTab():string {
        return props.textLinkLogoSpec.openNewTab? '_blank':'';
    }

</script>

<script lang="ts">
    var buttonObject:IControlButton = {
        buttonElName: "text-link",
        buttonText: "Find out more",
        class: "",
        enableUrl: true,
        externalSource: false,
        openNewTab: false,
        sourceUrl: "/",
        type: "signout"
    }
</script>